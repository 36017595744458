import React, { Component, Fragment } from "react";
import {
  Typography,
  Select,
  FormControl,
  InputBase,
  MenuItem,
  Grid,
  Divider,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  ListItemAvatar,
  ListItem,
  Paper,
  List,
} from "@material-ui/core";
import down_arrow from "../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { styles } from "./PointsTransactionStyles";
import moment from "moment";
import { green, maroonRed } from "../../../components/UI/Theme";
import NoRecordFound from "../../NoRecordFound/NoRecordFound";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    fontSize: "20px",
    padding: "10px 24px 10px 32px",
    textAlign: "left",
    backgroundColor: "#fff",
    border: "solid 1px #e4e4e4",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 24px 8px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 24px 6px 16px",
    },
    [theme.breakpoints.down("641")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("481")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("381")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("321")]: {
      fontSize: "12px",
    },
  },
}))(InputBase);

const points = [
  { name: "All Points", val: 0 },
  { name: "Earned Points", val: 1 },
  { name: "Redeemed Points", val: 2 },
  { name: "Expiring Points", val: 3 },
  { name: "Expired Points", val: 4 },
  { name: "Archived Points", val: 5 },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

class PointsTransaction extends Component {
  itemClicked = (record) => {
    if (record.earned_redeemed_flag) {
      if (
        record.earned_redeemed_flag === "1" &&
        record.reference_id !== "0" &&
        (record.earn_or_redeemed_reason_id === "1" ||
          record.earn_or_redeemed_reason_id === "3")
      ) {
        this.props.history.push({
          pathname: "/bill-summary",
          state: {
            billIds: record.reference_id,
            from: "/transactions",
            withDetailClicked: true,
          },
        });
      } else if (record.earned_redeemed_flag === "2") {
        // if (this.props.activeTab === 2) {
        // 	return;
        // }
        this.props.history.push({
          pathname: "/order-summary",
          state: {
            id: record.order_id,
            from: "/transactions",
          },
        });
      } else {
        return;
      }
    }
  };

  render() {
    const {
      classes,
      logData,
      pointsSelection,
      classObj,
      ptShortName,
      activeTab,
      noRecordFound,
    } = this.props;
    const showingExpiringDate = moment().add(12, "M").format("DD/MM/YYYY");

    return (
      <Fragment>
        {activeTab !== 2 && (
          <FormControl fullWidth className={classes.formControl}>
            <Select
              name="points"
              labelId="points"
              id="points"
              value={pointsSelection}
              onChange={(e) => this.props.handlePointsChange(e)}
              input={<CustomInput />}
              IconComponent={() => (
                <img
                  className={classes.down_icon}
                  src={down_arrow}
                  alt=""
                  width="10"
                  height="6"
                />
              )}
              MenuProps={MenuProps}
            >
              {points.map((point) => (
                <MenuItem
                  className={classes.menuItem}
                  key={point.val}
                  value={point.val}
                >
                  {point.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {pointsSelection === 4 ? (
          <Fragment>
            {Object.keys(logData).length > 0 ? (
              Object.keys(logData).map((item, index) => {
                return (
                  <Grid key={index}>
                    <Paper className={classObj.paperContainer} key={index}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={item}
                            classes={{ primary: classObj.listTitleText }}
                          />
                          <ListItemSecondaryAction
                            className={classObj.listTitleSecondaryContainer}
                          >
                            {/* <Typography className={classObj.listColumnNames}>
                              Earned
                            </Typography>
                            <Typography className={classObj.listColumnNames}>
                              Redeemed
                            </Typography> */}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="middle" />
                        {Object.keys(logData).length > 0 &&
                          logData[item].map((record, number) => {
                            return pointsSelection === 4 &&
                              record.points_expiry_date &&
                              !moment(
                                moment(
                                  moment(record.points_expiry_date).format(
                                    "DD/MM/YYYY"
                                  ),
                                  "DD/MM/YYYY"
                                )
                              ).isSameOrBefore(
                                moment(showingExpiringDate, "DD/MM/YYYY")
                              ) ? null : (
                              <div key={number}>
                                <ListItem
                                  button
                                  onClick={() => this.itemClicked(record)}
                                >
                                  <ListItemAvatar
                                    className={classObj.avtartCss}
                                  >
                                    {pointsSelection !== 4 ? (
                                      record.earned_redeemed_flag === "1" ? (
                                        <Avatar
                                          aria-label="recipe"
                                          className={
                                            classObj.green +
                                            " " +
                                            classObj.avtartCss
                                          }
                                        >
                                          Ea
                                        </Avatar>
                                      ) : (
                                        <Avatar
                                          aria-label="recipe"
                                          className={
                                            classObj.red +
                                            " " +
                                            classObj.avtartCss
                                          }
                                        >
                                          Re
                                        </Avatar>
                                      )
                                    ) : (
                                      <Avatar
                                        aria-label="recipe"
                                        className={
                                          classObj.red +
                                          " " +
                                          classObj.avtartCss
                                        }
                                      >
                                        X
                                      </Avatar>
                                    )}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      pointsSelection !== 4
                                        ? record.date_earned_or_redeemed &&
                                          moment(
                                            record.date_earned_or_redeemed
                                          ).format("DD/MM/YYYY")
                                        : record.points_expiry_date &&
                                          moment(
                                            record.points_expiry_date
                                          ).format("DD/MM/YYYY")
                                    }
                                    secondary={
                                      pointsSelection !== 4 ? (
                                        record.reference_id !== "0" ? (
                                          record.earn_or_redeemed_reason_id ===
                                            "1" ||
                                          record.earn_or_redeemed_reason_id ===
                                            "3" ? (
                                            <Fragment>
                                              <span
                                                className={
                                                  classObj.listSecondaryText
                                                }
                                              >
                                                {record.earned_redeemed_flag ===
                                                "1"
                                                  ? `Bill #: ${record.bill_number}`
                                                  : `Order #: ${record.reference_id}`}
                                              </span>
                                              <span
                                                className={
                                                  classObj.listBrandText
                                                }
                                              >
                                                {record.brand &&
                                                  record.brand[0]
                                                    .brand_name}{" "}
                                                {record.brand.length - 1 !==
                                                  0 &&
                                                  `+${record.brand.length - 1}`}
                                              </span>
                                            </Fragment>
                                          ) : record.earn_or_redeemed_reason_id ===
                                            "2" ? (
                                            <span
                                              className={
                                                classObj.listSecondaryText
                                              }
                                            >
                                              {
                                                record.earn_or_redeemed_reason_other
                                              }
                                            </span>
                                          ) : (
                                            record.earn_or_redeemed_reason_id ===
                                              "4" && (
                                              <span
                                                className={
                                                  classObj.listSecondaryText
                                                }
                                              >
                                                {
                                                  record.earn_or_redeemed_reason_other
                                                }
                                              </span>
                                            )
                                          )
                                        ) : record.earn_or_redeemed_reason_id ===
                                          "2" ? (
                                          <span
                                            className={
                                              classObj.listSecondaryText
                                            }
                                          >
                                            {
                                              record.earn_or_redeemed_reason_other
                                            }
                                          </span>
                                        ) : record.earn_or_redeemed_reason_id ===
                                            "4" &&
                                          record.earned_redeemed_flag ===
                                            "1" ? (
                                          <span
                                            className={
                                              classObj.listSecondaryText
                                            }
                                          >
                                            {record.earn_or_redeemed_reason_other !==
                                            ""
                                              ? record.earn_or_redeemed_reason_other
                                              : "Bonus Points"}
                                          </span>
                                        ) : null
                                      ) : null
                                    }
                                    classes={{
                                      root: classObj.listItemTextRoot,
                                      primary: classObj.listPrimaryText,
                                    }}
                                  />
                                  <ListItemSecondaryAction
                                    className={classObj.listPointsContainer}
                                  >
                                    {pointsSelection !== 4
                                      ? record.earned_redeemed_flag === "1"
                                        ? record.points_earned_or_redeemed && (
                                            <Typography
                                              className={classObj.listPoints}
                                              style={{
                                                color: green,
                                                float:
                                                  pointsSelection === 1
                                                    ? "right"
                                                    : "left",
                                                textAlign:
                                                  pointsSelection === 1
                                                    ? "right"
                                                    : "left",
                                              }}
                                            >
                                              +{" "}
                                              {this.props.roundOff(
                                                record.points_earned_or_redeemed
                                              )}{" "}
                                              {ptShortName}
                                            </Typography>
                                          )
                                        : record.points_earned_or_redeemed && (
                                            <Typography
                                              className={classObj.listPoints}
                                              style={{
                                                color: maroonRed,
                                                float: "right",
                                                textAlign: "right",
                                              }}
                                            >
                                              -{" "}
                                              {this.props.roundOff(
                                                record.points_earned_or_redeemed
                                              )}{" "}
                                              {ptShortName}
                                            </Typography>
                                          )
                                      : record.points_earned && (
                                          <Typography
                                            className={classObj.listPoints}
                                            style={{
                                              color: maroonRed,
                                              float: "right",
                                              textAlign: "right",
                                            }}
                                          >
                                            {this.props.roundOff(
                                              record.points_earned
                                            )}{" "}
                                            {ptShortName}
                                          </Typography>
                                        )}
                                  </ListItemSecondaryAction>
                                </ListItem>
                                {((number !== logData[item].length - 1 &&
                                  pointsSelection !== 4) ||
                                  (number !== logData[item].length - 1 &&
                                    pointsSelection === 4 &&
                                    record.points_expiry_date &&
                                    moment(
                                      moment(
                                        moment(
                                          record.points_expiry_date
                                        ).format("DD/MM/YYYY"),
                                        "DD/MM/YYYY"
                                      )
                                    ).isSameOrBefore(
                                      moment(showingExpiringDate, "DD/MM/YYYY")
                                    ))) && <Divider variant="middle" light />}
                              </div>
                            );
                          })}
                      </List>
                    </Paper>
                  </Grid>
                );
              })
            ) : noRecordFound ? (
              <NoRecordFound message={"No transactions found"} />
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          <Fragment>
            {Object.keys(logData).length > 0 && pointsSelection === 3 && (
              <Typography className={classObj.expiryNote}>
                Expiring in the next 12 months.
              </Typography>
            )}
            {Object.keys(logData).length > 0 ? (
              Object.keys(logData).map((item, index) => {
                return (
                  <Grid key={index}>
                    <Paper className={classObj.paperContainer} key={index}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={item}
                            classes={{ primary: classObj.listTitleText }}
                          />
                          {activeTab !== 2 && pointsSelection === 0 && (
                            <ListItemSecondaryAction
                              className={classObj.listTitleSecondaryContainer}
                            >
                              <Typography className={classObj.listColumnNames}>
                                Earned
                              </Typography>
                              <Typography className={classObj.listColumnNames}>
                                Redeemed
                              </Typography>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                        <Divider variant="middle" />
                        {Object.keys(logData).length > 0 &&
                          logData[item].map((record, number) => {
                            return pointsSelection === 3 &&
                              record.points_expiry_date &&
                              !moment(
                                moment(
                                  moment(record.points_expiry_date).format(
                                    "DD/MM/YYYY"
                                  ),
                                  "DD/MM/YYYY"
                                )
                              ).isSameOrBefore(
                                moment(showingExpiringDate, "DD/MM/YYYY")
                              ) ? null : (
                              <div key={number}>
                                <ListItem
                                  button
                                  onClick={() => this.itemClicked(record)}
                                >
                                  <ListItemAvatar
                                    className={classObj.avtartCss}
                                  >
                                    {pointsSelection !== 3 ? (
                                      record.earned_redeemed_flag === "1" ? (
                                        <Avatar
                                          aria-label="recipe"
                                          className={
                                            classObj.green +
                                            " " +
                                            classObj.avtartCss
                                          }
                                        >
                                          Ea
                                        </Avatar>
                                      ) : (
                                        <Avatar
                                          aria-label="recipe"
                                          className={
                                            classObj.red +
                                            " " +
                                            classObj.avtartCss
                                          }
                                        >
                                          Re
                                        </Avatar>
                                      )
                                    ) : (
                                      <Avatar
                                        aria-label="recipe"
                                        className={
                                          classObj.red +
                                          " " +
                                          classObj.avtartCss
                                        }
                                      >
                                        X
                                      </Avatar>
                                    )}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      pointsSelection !== 3
                                        ? record.date_earned_or_redeemed &&
                                          moment(
                                            record.date_earned_or_redeemed
                                          ).format("DD/MM/YYYY")
                                        : record.points_expiry_date &&
                                          moment(
                                            record.points_expiry_date
                                          ).format("DD/MM/YYYY")
                                    }
                                    secondary={
                                      pointsSelection !== 3 ? (
                                        record.reference_id !== "0" ? (
                                          record.earn_or_redeemed_reason_id ===
                                            "1" ||
                                          record.earn_or_redeemed_reason_id ===
                                            "3" ? (
                                            <Fragment>
                                              <span
                                                className={
                                                  classObj.listSecondaryText
                                                }
                                              >
                                                {record.earned_redeemed_flag ===
                                                "1"
                                                  ? `Bill #: ${record.bill_number}`
                                                  : `Order #: ${record.reference_id}`}
                                              </span>
                                              <span
                                                className={
                                                  classObj.listBrandText
                                                }
                                              >
                                                {record.brand &&
                                                  record.brand[0]
                                                    .brand_name}{" "}
                                                {record.brand.length - 1 !==
                                                  0 &&
                                                  `+${record.brand.length - 1}`}
                                              </span>
                                            </Fragment>
                                          ) : record.earn_or_redeemed_reason_id ===
                                            "2" ? (
                                            <span
                                              className={
                                                classObj.listSecondaryText
                                              }
                                            >
                                              {
                                                record.earn_or_redeemed_reason_other
                                              }
                                            </span>
                                          ) : (
                                            record.earn_or_redeemed_reason_id ===
                                              "4" && (
                                              <span
                                                className={
                                                  classObj.listSecondaryText
                                                }
                                              >
                                                {
                                                  record.earn_or_redeemed_reason_other
                                                }
                                              </span>
                                            )
                                          )
                                        ) : record.earn_or_redeemed_reason_id ===
                                          "2" ? (
                                          <span
                                            className={
                                              classObj.listSecondaryText
                                            }
                                          >
                                            {
                                              record.earn_or_redeemed_reason_other
                                            }
                                          </span>
                                        ) : record.earn_or_redeemed_reason_id ===
                                            "4" &&
                                          record.earned_redeemed_flag ===
                                            "1" ? (
                                          <span
                                            className={
                                              classObj.listSecondaryText
                                            }
                                          >
                                            {record.earn_or_redeemed_reason_other !==
                                            ""
                                              ? record.earn_or_redeemed_reason_other
                                              : "Bonus Points"}
                                          </span>
                                        ) : null
                                      ) : null
                                    }
                                    classes={{
                                      root: classObj.listItemTextRoot,
                                      primary: classObj.listPrimaryText,
                                    }}
                                  />
                                  <ListItemSecondaryAction
                                    className={classObj.listPointsContainer}
                                  >
                                    {pointsSelection !== 3
                                      ? record.earned_redeemed_flag === "1"
                                        ? record.points_earned_or_redeemed && (
                                            <Typography
                                              className={classObj.listPoints}
                                              style={{
                                                color: green,
                                                float:
                                                  pointsSelection === 1
                                                    ? "right"
                                                    : "left",
                                                textAlign:
                                                  pointsSelection === 1
                                                    ? "right"
                                                    : "left",
                                              }}
                                            >
                                              +{" "}
                                              {this.props.roundOff(
                                                record.points_earned_or_redeemed
                                              )}{" "}
                                              {ptShortName}
                                            </Typography>
                                          )
                                        : record.points_earned_or_redeemed && (
                                            <Typography
                                              className={classObj.listPoints}
                                              style={{
                                                color: maroonRed,
                                                float: "right",
                                                textAlign: "right",
                                              }}
                                            >
                                              -{" "}
                                              {this.props.roundOff(
                                                record.points_earned_or_redeemed
                                              )}{" "}
                                              {ptShortName}
                                            </Typography>
                                          )
                                      : record.points_earned && (
                                          <Typography
                                            className={classObj.listPoints}
                                            style={{
                                              color: maroonRed,
                                              float: "right",
                                              textAlign: "right",
                                            }}
                                          >
                                            {this.props.roundOff(
                                              record.points_earned
                                            )}{" "}
                                            {ptShortName}
                                          </Typography>
                                        )}
                                  </ListItemSecondaryAction>
                                </ListItem>
                                {((number !== logData[item].length - 1 &&
                                  pointsSelection !== 3) ||
                                  (number !== logData[item].length - 1 &&
                                    pointsSelection === 3 &&
                                    record.points_expiry_date &&
                                    moment(
                                      moment(
                                        moment(
                                          record.points_expiry_date
                                        ).format("DD/MM/YYYY"),
                                        "DD/MM/YYYY"
                                      )
                                    ).isSameOrBefore(
                                      moment(showingExpiringDate, "DD/MM/YYYY")
                                    ))) && <Divider variant="middle" light />}
                              </div>
                            );
                          })}
                      </List>
                    </Paper>
                  </Grid>
                );
              })
            ) : noRecordFound ? (
              <NoRecordFound message={"No transactions found"} />
            ) : (
              ""
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(PointsTransaction)
);
