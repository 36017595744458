import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/styles";
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { styles } from '../../components/MiBrowserDialog/MiBrowserDialogStyles';
import { merchantDetails, } from "../../containers/Screens/Dashboard/redux/action";
const DialogStyles = {
    dialogTitle: {
        textAlign: "center"

    },
    dialogActions: {
        justifyContent: 'center'
    },
    dialogButton: {
        width: '28%',
        height: '30px'
    }

}
class PendamicPopupModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isModalVisible: false
        }
    }
    componentDidMount() {
        if (this.props.isVisible && this.props.isVisible !== "1") {
            this.setState({ isModalVisible: true })
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    maxWidth="md"
                    // className={classes.dialogModal}
                    open={this.state.isModalVisible}
                >
                    {/* <DialogTitle style={styles.dialogTitle}>{this.props.message?this.props.message:""}</DialogTitle> */}
                    <div style={{padding:'10px'}}>
                        <Typography className={classes.subHeading}>{this.props.message ? this.props.message : ""}</Typography>
                    </div>
                    <DialogActions style={DialogStyles.dialogActions}>
                        <Button

                            style={DialogStyles.dialogButton}
                            color="primary"

                            className={classes.okBtn}
                            onClick={() => {
                                this.setState({ isModalVisible: false });
                                this.props.history.replace("/dashboard")
                            }}

                            color="primary"> OK   </Button>

                    </DialogActions>

                </Dialog>

            </div>
        )
    }
}
export default withRouter(withStyles(styles, { withTheme: true })(PendamicPopupModal))
