import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import OtpInput from "../../../../components/OTPInput/index";
import { stockAPI } from "../../../../common/axiosInstance";
import {
  setStorageItem,
  getStorageItem,
  removeStorageItem,
} from "../../../../common/localStorage";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { apiData } from "../../../../common/common-types";
import styles from "./OtpVerificationStyle";
import { isPWAInstalled, customerLoginLogs } from "../../../../common/commonFunctions";
import otpicon from "../../../../assets/icons/login/mobileicon.svg";
import { encrypt } from "../../../../crypto-helper";

class OtpVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      Otpdisabled: false,
      isOTPAttemptExceeded: false,
      otpcode: "",
      time: {},
      timeOtp: {},
      seconds: parseInt(getStorageItem("otpTime")) || 120,
      secondsOtp: parseInt(getStorageItem("otpExpireTime")) || 30,
      errorRes: false,
      messageRes: "",
      count: 1,
      numInputs: 4,
      disableResend: false,
      mobileNo:
        props.history.location.state && props.history.location.state.mobileNo
          ? props.history.location.state.mobileNo
          : "",
      userId:
        props.history.location.state && props.history.location.state.userid
          ? props.history.location.state.userid
          : "",
      clearInput: false,
    };
    this.inputRef = React.createRef();
    this.timer = 0;
    this.otpTimer = 0;
    this.countDown = this.countDown.bind(this);
    this.handleResendotp = this.handleResendotp.bind(this);
    this.handleEditlink = this.handleEditlink.bind(this);
    this.handleVerifyotp = this.handleVerifyotp.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    const { seconds } = this.state;
    const { isOtpTime, setOTPTimeFlag, wrongOtpCount } = this.props;
    if (window.performance) {
      if (performance.navigation.type === 1 && isOtpTime) {
        if (
          wrongOtpCount > 2 &&
          parseInt(getStorageItem("otpExpireTime")) !== 0
        ) {
          this.counterWrongAttempt();
        }
        if (parseInt(getStorageItem("otpTime")) !== 0) {
          let timeLeftVar = this.secondsToTime(
            parseInt(getStorageItem("otpTime"))
          );
          this.timer = setInterval(this.countDown, 1000);
          this.setState({
            time: timeLeftVar,
          });
        } else {
          this.setState({ disabled: true, Otpdisabled: true });
        }
        this.setState({
          mobileNo:
            this.props.history.location.state &&
              this.props.history.location.state.usermobile
              ? this.props.history.location.state.usermobile
              : "",
        });
      }
    }

    setOTPTimeFlag(true);

    if (parseInt(getStorageItem("otpTime")) !== 0) {
      let timeLeftVar = this.secondsToTime(seconds);
      if (this.timer === 0 && seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
      this.setState({
        time: timeLeftVar,
        mobileNo:
          this.props.history.location.state &&
            this.props.history.location.state.usermobile
            ? this.props.history.location.state.usermobile
            : "",
      });
    }
  }

  allAttemptFinished = () => {
    clearInterval(this.timer);
    clearInterval(this.otpTimer);
    setStorageItem("otpTime", 0);
    setStorageItem("otpExpireTime", 0);
    openAlertBox(
      "Exceeded maximum attempts allowed to resend OTP. Please start again!",
      "error"
    );
    setTimeout(() => this.props.history.replace("/"), 5000);
  };

  countDown() {
    // Remove one second, set state so a re-render happens.
    const { resendCount } = this.props;
    let seconds = this.state.seconds - 1;
    setStorageItem("otpTime", seconds);
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      if (resendCount === 3) {
        this.allAttemptFinished();
        this.setState({
          disableResend: true,
          Otpdisabled: true,
          isOTPAttemptExceeded: false,
        });
      }
      // after time out disable verify otp button
      this.setState({ disabled: true, Otpdisabled: true, clearInput: true });
    }
  }

  secCountDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.secondsOtp - 1;
    setStorageItem("otpExpireTime", seconds);

    this.setState({
      timeOtp: this.secondsToTime(seconds),
      secondsOtp: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.otpTimer);
      // after time out disable verify otp button
      this.setState({
        isOTPAttemptExceeded: false,
        messageRes: " ",
        errorRes: false,
        disabled: true,
        secondsOtp: 30,
      });
    }
  };

  handleResendotp() {
    const { resendCount, setResendCount, resetTryCount } = this.props;
    openAlertBox("OTP resent successfully!", "success");
    clearInterval(this.timer);
    clearInterval(this.otpTimer);
    if (resendCount === 3) {
      setResendCount();
      this.setState({ disableResend: true });
    }
    if (resendCount <= 3) {
      if (resendCount === 0) {
        this.setState({
          seconds: 121,
          secondsOtp: 30,
          otpcode: "",
          clearInput: true,
          Otpdisabled: false,
          disabled: true,
        });
      } else {
        this.setState({
          seconds: 121,
          secondsOtp: 31,
          otpcode: "",
          clearInput: true,
          Otpdisabled: false,
          disabled: true,
        });
      }

     const formData = {
        mobile_number: this.state.mobileNo,
        platform: apiData.platform,
        transactionType: 201,
        merchant_id: apiData.merchant_id,
        customer_id:
          getStorageItem("user") && JSON.parse(getStorageItem("user")).id,
      };

      let encryptedPayload = {
        _k: encrypt(JSON.stringify(formData)),
      };
      stockAPI(encryptedPayload, "POST", "/getOtp", null, null, null, true)
        .then(async (res) => {
          resetTryCount();
          if (res && res.statusCode === 100) {
            setResendCount();
            this.setState({
              userId: res.response.id,
              mobileNo:
                this.props.history.location.state &&
                  this.props.history.location.state.usermobile
                  ? this.props.history.location.state.usermobile
                  : "",
              disabled: false,
            });
          } else if (res.status === "error" && res.error === "FAILED_MAX_LOGIN_ATTEMPT") {
            this.props.showLoader(false);
            openAlertBox(res.message, "error")
          } else if(res?.statusCode === 102 && res?.statusDescription?.toLowerCase() === "otp already sent.") {
            this.props.showLoader(false);
            openAlertBox(res?.statusDescription || "Oops something went wrong!", "error");
          }else{
            openAlertBox("Oops something went wrong!", "error");
          }
        })
        .catch(function (err) {
          openAlertBox(err && err.message, "error");
        });
    }
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.timer = setInterval(this.countDown, 1000);
  }

  handleEditlink() {
    clearInterval(this.timer);
    this.props.history.replace("/");
  }

  handleVerifyotp(e) {

    e.preventDefault();
    const {
      wrongOtpCount,
      setTryCount,
      resendCount,
      setRegVisitedflag,
      showLoader,
    } = this.props;
    showLoader(true);
    if (resendCount === 3 && wrongOtpCount > 2) {
      this.allAttemptFinished();
      setStorageItem("otpExpireTime", 0);
      setStorageItem("otpTime", 0);
    }
    // verify otp api call
    const formData = {
      mobile_number: this.state.mobileNo,
      otp: parseInt(this.state.otpcode),
      id: parseInt(this.state.userId),
      platform: apiData.platform,
      transactionType: 201,
      merchant_id: apiData.merchant_id, // change static data value
    };
    let encryptedPayload = {
      _k: encrypt(JSON.stringify(formData)),
    };
    return stockAPI(encryptedPayload, "POST", "/loginCustomer", null, null, null, true)
      .then(async (res) => {
        if (res && res.statusCode === 102) {
          if (wrongOtpCount >= 2) {
            this.counterWrongAttempt();
          }
          setTryCount();
          if (wrongOtpCount < 2) {
            this.setState({
              messageRes: res.statusDescription,
              errorRes: true,
              otpcode: "",
              clearInput: true,
            });
          }
          showLoader(false);
        } else if (
          res &&
          res.response &&
          res.response.isBoomerUserRegister === "1" &&
          res.response.isUserRegisteredForMerchant === "1" &&
          res.response.isUserRegisterationComplete === "1"
        ) {
          const user = {
            id: res.response.merchant_list.added[1].customerDetails.id,
            mobile_number:
              res.response.merchant_list.added[1].customerDetails.mobile_number,
          };
          const currency_code =
            res.response.merchant_list.added[1].loyalty_currency_code;
          const points_unit_short_name =
            res.response.merchant_list.added[1].points_unit_short_name;
          const loyalty_id = res.response.merchant_list.added[1].loyalty_id;

          const guidelinesFlag =
            res.response.merchant_list.added[1].customerDetails
              .bill_upload_guidelines_flag;
          await setStorageItem("user", JSON.stringify(user));
          await setStorageItem("currencyCode", `${currency_code}`);
          await setStorageItem("pointShortName", points_unit_short_name);
          await setStorageItem("loyaltyId", loyalty_id);
          await setStorageItem("guidelinesFlag", guidelinesFlag);
          const token = res?.response?.token?.token || '';
					const refreshToken = res?.response?.token?.refresh_token || '';
					await localStorage.setItem("acc_token", token);
					await localStorage.setItem("refresh_token", refreshToken);	
          if (getStorageItem("user")) {
            isPWAInstalled(
              res.response.merchant_list.added[1].customerDetails.mobile_number
            );
            clearInterval(this.timer);
            this.props.authenticate();
            this.props.setOTPVerifiedFlag(true);
            if (getStorageItem("prev_route")) {
              this.props.history.push(getStorageItem("prev_route"));
              removeStorageItem("prev_route");
            } else {
              this.props.history.push("/dashboard");
              customerLoginLogs();
            }
          }
          showLoader(false);
        } else if (
          res &&
          res.response &&
          res.response.isBoomerUserRegister === "1" &&
          (res.response.isUserRegisteredForMerchant === "0" ||
            res.response.isUserRegisterationComplete === "0")
        ) {
          clearInterval(this.timer);
          this.props.setOTPVerifiedFlag(true);
          setRegVisitedflag(true);

          const token = res?.response?.token?.token || '';
					const refreshToken = res?.response?.token?.refresh_token || '';
					await localStorage.setItem("acc_token", token);
					await localStorage.setItem("refresh_token", refreshToken);	
          this.props.history.push({
            pathname: "/registration",
            state: {
              usermobile: this.state.mobileNo,
            },
          });
          showLoader(false);
        } else {
          let  referalCode= res.response&&res.response.isReferralEnabled&&res.response.isReferralEnabled;
          this.props.isReferalEnable(referalCode=="1"?true:false);
          clearInterval(this.timer);
          this.props.setOTPVerifiedFlag(true);
          setRegVisitedflag(true);
          this.props.history.push({
            pathname: "/registration",
            state: {
              usermobile: this.state.mobileNo,
            },
          });
          showLoader(false);
        }
      })
      .catch(function (err) {
        openAlertBox(err && err.message, "error");
        showLoader(false);
      });
  }

  updateOtp = (otp) => {
    const re = /^[0-9]*$/;
    if (re.test(otp)) {
      this.setState({ otpcode: otp, errorRes: false, clearInput: false });
    } else {
      return false;
    }
  };

  handleVerifyAutoOtp = (auto_otp) => {
    const {
      wrongOtpCount,
      setTryCount,
      resendCount,
      setRegVisitedflag,
      showLoader,
    } = this.props;
   const formData = {
     mobile_number: this.state.mobileNo,
     otp: parseInt(auto_otp),
     id: parseInt(this.state.userId),
     platform: apiData.platform,
     transactionType: 201,
     merchant_id: apiData.merchant_id, // change static data value
   };
   let encryptedPayload = {
     _k: encrypt(JSON.stringify(formData)),
   };
    return stockAPI(encryptedPayload, "POST", "/loginCustomer", null, null, null, true)
      .then(async (res) => {
        if (res && res.statusCode === 102) {
          if (wrongOtpCount >= 2) {
            this.counterWrongAttempt();
          }
          setTryCount();
          if (wrongOtpCount < 2) {
            this.setState({
              messageRes: res.statusDescription,
              errorRes: true,
              otpcode: "",
              clearInput: true,
            });
          }
          showLoader(false);
        } else if (
          res &&
          res.response &&
          res.response.isBoomerUserRegister === "1" &&
          res.response.isUserRegisteredForMerchant === "1" &&
          res.response.isUserRegisterationComplete === "1"
        ) {

          const user = {
            id: res.response.merchant_list.added[1].customerDetails.id,
            mobile_number:
              res.response.merchant_list.added[1].customerDetails.mobile_number,
          };
          const currency_code =
            res.response.merchant_list.added[1].loyalty_currency_code;
          const points_unit_short_name =
            res.response.merchant_list.added[1].points_unit_short_name;
          const loyalty_id = res.response.merchant_list.added[1].loyalty_id;

          const guidelinesFlag =
            res.response.merchant_list.added[1].customerDetails
              .bill_upload_guidelines_flag;
          await setStorageItem("user", JSON.stringify(user));
          await setStorageItem("currencyCode", `${currency_code}`);
          await setStorageItem("pointShortName", points_unit_short_name);
          await setStorageItem("loyaltyId", loyalty_id);
          await setStorageItem("guidelinesFlag", guidelinesFlag);

          const token = res?.response?.token?.token || ''; 
					const refreshToken = res?.response?.token?.refresh_token || '';
					await localStorage.setItem("acc_token", token);
					await localStorage.setItem("refresh_token", refreshToken);	

          if (getStorageItem("user")) {
            isPWAInstalled(
              res.response.merchant_list.added[1].customerDetails.mobile_number
            );
            clearInterval(this.timer);
            this.props.authenticate();
            this.props.setOTPVerifiedFlag(true);
            if (getStorageItem("prev_route")) {
              this.props.history.push(getStorageItem("prev_route"));
              removeStorageItem("prev_route");
            } else {
              this.props.history.push("/dashboard");
              customerLoginLogs();
            }
          }
          showLoader(false);
        } else if (
          res &&
          res.response &&
          res.response.isBoomerUserRegister === "1" &&
          (res.response.isUserRegisteredForMerchant === "0" ||
            res.response.isUserRegisterationComplete === "0")
        ) {
          clearInterval(this.timer);
          const token = res?.response?.token?.token || '';
					const refreshToken = res?.response?.token?.refresh_token || '';
					await localStorage.setItem("acc_token", token);
					await localStorage.setItem("refresh_token", refreshToken);	
          this.props.setOTPVerifiedFlag(true);
          setRegVisitedflag(true);
          this.props.history.push({
            pathname: "/registration",
            state: {
              usermobile: this.state.mobileNo,
            },
          });
          showLoader(false);
        } else {
          let  referalCode= res.response&&res.response.isReferralEnabled&&res.response.isReferralEnabled;
          this.props.isReferalEnable(referalCode=="1"?true:false);
          clearInterval(this.timer);
          const token = res?.response?.token?.token || '';
					const refreshToken = res?.response?.token?.refresh_token || '';
					await localStorage.setItem("acc_token", token);
					await localStorage.setItem("refresh_token", refreshToken);	
          this.props.setOTPVerifiedFlag(true);
          setRegVisitedflag(true);
          this.props.history.push({
            pathname: "/registration",
            state: {
              usermobile: this.state.mobileNo,
            },
          });
          showLoader(false);
        }
      })
      .catch(function (err) {
        openAlertBox(err && err.message, "error");
        showLoader(false);
      });
  };

  counterWrongAttempt = async () => {
    let timeLeftVar = this.secondsToTime(this.state.secondsOtp);
    await this.setState({ timeOtp: timeLeftVar });
    await this.setState({
      messageRes: "Exceeded maximum attempts. Try again in  ",
      errorRes: true,
      otpcode: "",
      clearInput: true,
      isOTPAttemptExceeded: true,
      Otpdisabled: true,
    });

    this.otpTimer = setInterval(this.secCountDown, 1000);
    clearInterval(this.timer);
    setStorageItem("otpTime", 0);
  };

  componentWillUnmount() {
    const {
      setOTPTimeFlag,
      resetTryCount,
      resetResendCount,
      setExceedOtpTimeFlag,
    } = this.props;
    clearInterval(this.otpTimer);
    clearInterval(this.timer);
    setOTPTimeFlag(false);
    resetTryCount();
    resetResendCount();
    setExceedOtpTimeFlag(false);
    localStorage.removeItem("otpTime");
    localStorage.removeItem("otpExpireTime");
  }

  render() {
    const {
      classes,
      isOtpVerified,
      history,
      resendCount,
      wrongOtpCount,
    } = this.props;
    const {
      otpcode,
      numInputs,
      mobileNo,
      errorRes,
      messageRes,
      time,
      timeOtp,
      Otpdisabled,
      disableResend,
      isOTPAttemptExceeded,
      clearInput,
    } = this.state;

    if (isOtpVerified) {
      history.replace("/");
    }
    if (resendCount > 3 || (resendCount === 3 && wrongOtpCount === 3)) {
      this.allAttemptFinished();
    }
    return (
      <div className={classes.main_root}>
        <div className={classes.wrapper}>
          {/* <span className={classes.icons + " " + classes.otpIconCss} /> */}
          <img src={otpicon} alt="otp" className={classes.icons} />
          <Typography variant="h1" className={classes.heading}>
            OTP Verification
          </Typography>
          <Typography variant="h3" className={classes.subHeading}>
            Please enter 4 digit One Time Password sent to your mobile number
            +91 {mobileNo}
            <Link
              className={classes.codeSpanEdit}
              onClick={this.handleEditlink}
            >
              (edit)
            </Link>
          </Typography>
          <form noValidate onSubmit={this.handleVerifyotp}>
            {errorRes === true && wrongOtpCount !== 3 ? (
              <div className={classes.errorCode + " " + classes.incorrectError}>
                {messageRes}
              </div>
            ) : (
              ""
            )}
            <div className={classes.otpBoxInput}>
              <OtpInput
                length={4}
                disabled={Otpdisabled}
                className={classes.otpContainer}
                inputClassName={classes.otpInput}
                isNumberInput
                autoFocus
                isOtpVerified={isOtpVerified}
                handleVerifyotp={this.handleVerifyAutoOtp}
                clear={clearInput}
                onChangeOTP={(otp) => this.updateOtp(otp)}
              />
            </div>
            {/* verifyotp error message */}
            {errorRes === true ? (
              isOTPAttemptExceeded &&
                parseInt(getStorageItem("otpExpireTime")) !== 0 ? (
                <div className={classes.errorCode}>
                  {messageRes + " " + timeOtp.s + " secs."}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {!Otpdisabled && parseInt(getStorageItem("otpTime")) !== 0 && (
              <div className={classes.code}>
                OTP expires in:
                <span className={classes.codeSpan}>
                  {" "}
                  {time.m}:
                  {time.s < "10" || time.s < "1:10" ? "0" + time.s : time.s}
                </span>
              </div>
            )}
            <div>
              <Button
                className={classes.verifyotp}
                disableRipple
                disabled={
                  otpcode.toString().length < numInputs ||
                  isOTPAttemptExceeded ||
                  this.state.disabled
                }
                color="primary"
                variant="contained"
                type="submit"
              >
                VERIFY OTP
              </Button>
            </div>
            {!disableResend && Otpdisabled && !isOTPAttemptExceeded ? (
              <Typography className={classes.linkCls}>
                <Link underline={"none"} onClick={this.handleResendotp}>
                  Resend OTP
                </Link>
              </Typography>
            ) : (
              <Typography className={classes.disableLinkCls}>
                <Link underline={"none"}>Resend OTP</Link>
              </Typography>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(OtpVerification)
);
