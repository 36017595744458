import { Card, CardActionArea, CardContent, Grid, Modal, Typography, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router";
import ScratchMask from "../../../../assets/images/scratch_card.jpg";
import ExpiredScratchMask from "../../../../assets/images/expired_ScratchCard.jpg";
import RedeemSubHeader from "../../../../components/RedeemSubHeader/RedeemSubHeader";
import redeemIcon from "../../../../assets/icons/dashboard/redeemIcon.svg";
import ScratchCard from "react-scratchcard";
import crossIcon from "../../../../assets/icons/dashboard/cross-gray.svg";
import giftIcon from "../../../../assets/icons/scratchCard/giftIcon.svg";
import { lightPink, whiteColor } from "../../../../components/UI/Theme";
import { stockAPI } from "../../../../common/axiosInstance";
import { apiData } from "../../../../common/common-types";
import { getStorageItem, setStorageItem } from "../../../../common/localStorage";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";

const styles = (theme) => {
    return {
        paddingGrid: {
            padding: "5px",
        },
        root: {
            // width: "calc(100% - 30px)",
            minHeight: "342px",
            [theme.breakpoints.down(641)]: {
                minHeight: "325px",
            },
            [theme.breakpoints.down(481)]: {
                minHeight: "249px",
            },
            [theme.breakpoints.down(430)]: {
                minHeight: "216px",
            },
            [theme.breakpoints.down(380)]: {
                minHeight: "202px",
            },
            [theme.breakpoints.down(361)]: {
                minHeight: "192px",
            },
            [theme.breakpoints.down(321)]: {
                minHeight: "174px",
            },
            "& .MuiCardActionArea-focusHighlight": {
                opacity: "0 !important",
            },
        },
        rootModal: {
            minHeight: "220px",
        },
        new_root: {
            display: "flex",
            flexDirection: "column",
            height: "100%",
            paddingBottom: "100px",
            [theme.breakpoints.down(641)]: {
                paddingBottom: "60px",

            },
            [theme.breakpoints.down(481)]: {
                paddingBottom: "60px",

            },
            [theme.breakpoints.down(430)]: {
                paddingBottom: "60px",

            },
            [theme.breakpoints.down(381)]: {
                paddingBottom: "60px",

            },
            [theme.breakpoints.down(321)]: {
                paddingBottom: "60px",

            },
        },
        cardContent: {
            padding: 0,
            // height: "172px",
            [theme.breakpoints.down(641)]: {
                height: "152px",
                paddingLeft: "18px",
            },
            [theme.breakpoints.down(481)]: {
                height: "114px",
                paddingLeft: "14px",
            },
            [theme.breakpoints.down(430)]: {
                height: "95px",
                paddingLeft: "10px",
            },
            [theme.breakpoints.down(381)]: {
                height: "89px",
                paddingLeft: "10px",
            },
            [theme.breakpoints.down(321)]: {
                height: "74px",
                paddingLeft: "9px",
            },
        },
        modalContainer: {
            height: "100%",
            width: '100%',
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            outline: 0,
            // [theme.breakpoints.down("641")]: {
            //     width: '310px',
            //     height: '310px',
            // },
            // [theme.breakpoints.down("481")]: {
            //     width: '310px',
            //     height: '310px',
            // },
            // [theme.breakpoints.down("381")]: {
            //     width: '310px',
            //     height: '310px',
            // },
            // [theme.breakpoints.down("321")]: {
            //     width: '310px',
            //     height: '310px',
            // },
        },
        cardContainer: {
            display: "flex",
            justifyContent: "center",
        },

        pointsCss: {
            fontSize: "24px",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontStretch: "normal",
            [theme.breakpoints.down(641)]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down(481)]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down(430)]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down(380)]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down(360)]: {
                fontSize: "16px",
            },
            [theme.breakpoints.down(321)]: {
                fontSize: "14px",
            },
        },
        MessageCss: {
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: "normal",
            fontStretch: "normal",
            [theme.breakpoints.down(641)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(481)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(430)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(380)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(360)]: {
                fontSize: "14px",
            },
            [theme.breakpoints.down(321)]: {
                fontSize: "14px",
            },
        },
        imageCss: {
            flex: 1,
            background: "cover",
        },
        giiftIconCss: {
            backgroundColor: lightPink,
            " -webkit-mask-image": `url(.${giftIcon})`,
            "mask-image": `url(.${giftIcon})`,
            mask: `url(.${giftIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${giftIcon}) no-repeat center / contain`,
        },
        giftIconContainerCss: {
            width: "90px",
            height: "90px",
            display: "inline-block",
            [theme.breakpoints.down("641")]: {
                width: "90px",
                height: "90px",
            },
            [theme.breakpoints.down("481")]: {
                width: "90px",
                height: "90px",
            },
            [theme.breakpoints.down("381")]: {
                width: "90px",
                height: "90px",
            },
            [theme.breakpoints.down("321")]: {
                width: "90px",
                height: "90px",
            },
        },
        iconWrapper: {
            padding: "10px",
            alignSelf: "end"
        },
        textWrapper: {
            padding: '10px',
        },
        WrapperCss: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",

        },
        closeBTn: {
            position: "absolute",
            width: '40px',
            height: '40px',
            top: "20px",
            right: "20px",
            outline: 0,
            [theme.breakpoints.down("641")]: {
                width: '40px',
                height: '40px',
            },
            [theme.breakpoints.down("481")]: {
                width: '25px',
                height: '25px',
            },
            [theme.breakpoints.down("381")]: {
                width: '25px',
                height: '25px',
            },
            [theme.breakpoints.down("321")]: {
                width: '25px',
                height: '25px',
            },
        },
        CloseIconCss: {
            width: "25px",
            height: "25px",
            "--size ": "70px",
            display: "inline-block",
            transition: ".12s",
            "-webkit-mask-repeat": "no-repeat",
            "-webkit-mask-size": "contain",
            "mask-size ": "contain",
            [theme.breakpoints.down("641")]: {
                width: "30px",
                height: "30px",
            },
            [theme.breakpoints.down("481")]: {
                width: "30px",
                height: "30px",
            },
            [theme.breakpoints.down("381")]: {
                width: "24px",
                height: "24px",
            },
            [theme.breakpoints.down("321")]: {
                width: "22px",
                height: "22px",
            },
        },
        crossIconCss: {
            backgroundColor: whiteColor,
            " -webkit-mask-image": `url(.${crossIcon})`,
            "mask-image": `url(.${crossIcon})`,
            mask: `url(.${crossIcon}) no-repeat center / contain`,
            "-webkit-mask": `url(.${crossIcon}) no-repeat center / contain`,
        },
        WrapperModalCss: {
            display: "flex",
            width: "200px",
            height: "220px",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: whiteColor,
            borderRadius: '5px'
        },
        cardScratch: {
            borderRadius: "5px"
        }
    }
}

class ScratchCardFun extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            cardData: [],
            userScratch: false,
            currentItem: [],
            dataArray: [],
            scratchPoint: 0,
        }
    }

    componentDidMount() {
        this.getScratchCardList();
    }

    getMerchantResponce() {
        const formData = new URLSearchParams();
        formData.append("platform", apiData.platform);
        formData.append("userId", JSON.parse(getStorageItem("user")).id);
        formData.append("merchant_id", apiData.merchant_id);
        formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
        this.props.getMerchantDetails(formData);
    }

    async getScratchCardList() {
        this.props.showLoader(true);
        const formData = new URLSearchParams();
        formData.append("merchant_id", apiData.merchant_id);
        formData.append(
            "customer_id",
            getStorageItem("user") && JSON.parse(getStorageItem("user")).id
        );
        formData.append(
            "loyalty_id",
            getStorageItem("loyaltyId") && (getStorageItem("loyaltyId"))
        );
        formData.append("page_num", 1)
        await stockAPI(formData, "POST", "/getScratchAndWinRewards").then((response) => {
            setStorageItem("redirectToRewards", "");
            if (response && response.scratchList) {
                this.getMerchantResponce();
                this.setState({ dataArray: response.scratchList });
                this.props.showLoader(false);
            }
        }).catch(err => {
            console.log(err)
            setStorageItem("redirectToRewards", "");
        })
    }
    fixedToTwoDecimal(number) {
        let num = Math.floor(number);
        return num;
    }
    cardView(elem, classes, pointsMsg) {
        return (
            <Card className={classes.root} style={{ display: "flex", backgroundImage: elem.is_scratched === "0" ? `url(${ScratchMask})` : "" }}>
                {elem && elem.is_scratched === "1" ?
                    <div className={classes.WrapperCss}>
                        <div className={classes.textWrapper}>
                            <Typography className={classes.pointsCss}>{this.fixedToTwoDecimal(elem.scratched_points)} Points</Typography>
                            <Typography className={classes.MessageCss}>{pointsMsg}</Typography>
                        </div>
                        <div
                            className={classes.iconWrapper}
                        >
                            <span
                                className={
                                    classes.giiftIconCss + " " + classes.giftIconContainerCss}
                            ></span>
                        </div>
                    </div >

                    : null
                }
            </Card>
        )
    }

    handleScratchComplete = async () => {
        this.props.showLoader(true);
        const formData = new URLSearchParams();
        formData.append("reward_id", this.state.currentItem.id);
        formData.append("merchant_id", apiData.merchant_id);
        formData.append(
            "customer_id",
            getStorageItem("user") && JSON.parse(getStorageItem("user")).id
        );
        await stockAPI(formData, "POST", "/addScratchPoints").then((res) => {
            if (res && res.scratched_points) {
                this.props.showLoader(false);
                this.setState({ scratchPoint: res.scratched_points, userScratch: true });
            }
        }).catch(err => { console.log(err) })

    };

    oncloseModal() {
        if (this.state.userScratch) {
            this.getScratchCardList();
            this.setState({ modalVisible: false, userScratch: false, scratchPoint: 0 })
        } else {
            this.setState({ modalVisible: false })
        }
    }
    render() {
        const { classes, merchantResponce } = this.props;
        const settings = {
            width: 200,
            height: 225,
            image: ScratchMask,
            finishPercent: 15,
            onComplete: () => {
                this.handleScratchComplete()
            }
        };
        const POINTS_MSG = "Credited";
        return (
            <div className={classes.new_root}>
                <RedeemSubHeader
                    isSubHeader={true}
                    isScratchCardView={true}
                    merchantDetailsResponse={merchantResponce}
                />

                {
                    this.state.dataArray && this.state.dataArray.length ?
                        < Grid container>
                            {this.state.dataArray &&
                                this.state.dataArray.length > 0 &&
                                this.state.dataArray.map((elem) => (
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={4}
                                        key={elem.id}
                                        className={classes.paddingGrid}
                                        onClick={() => {
                                            if (elem && elem.is_scratched === "0" && elem.status !== "2") {
                                                this.setState({
                                                    modalVisible: true,
                                                    currentItem: elem
                                                });
                                            }
                                        }}
                                    >
                                        { elem.status === "2" ?
                                            < img src={ExpiredScratchMask} style={{ width: "100%", height: "100%" }} />
                                            : elem.is_scratched === "0" ?
                                                < img src={ScratchMask} style={{ width: "100%", height: "100%" }} />
                                                : this.cardView(elem, classes, POINTS_MSG)
                                        }
                                    </Grid>))}
                        </Grid>
                        :
                        <NoRecordFound message={"Submit bills to earn surprise rewards"} />}
                <Modal
                    open={this.state.modalVisible}
                >
                    <div
                        className={classes.modalContainer}
                    >
                        <div
                            className={classes.closeBTn}
                            onClick={() => {
                                this.oncloseModal()
                            }}
                        >
                            <span
                                // onClick={async () => {
                                //     this.setState({ modalVisible: false })
                                // }}
                                className={
                                    classes.crossIconCss + " " + classes.CloseIconCss
                                }
                            ></span>
                        </div>
                        <ScratchCard {...settings}>
                            {/* <Card className={classes.rootModal}> */}
                            <div className={classes.WrapperModalCss}
                            >
                                <div className={classes.textWrapper}>
                                    <Typography className={classes.pointsCss}>{this.fixedToTwoDecimal(this.state.scratchPoint)} Points</Typography>
                                    {this.state.scratchPoint > 0 ? < Typography className={classes.MessageCss}>{POINTS_MSG}</Typography> : null}
                                </div>
                                <div
                                    className={classes.iconWrapper}
                                >
                                    <span
                                        className={
                                            classes.giiftIconCss + " " + classes.giftIconContainerCss}
                                    ></span>
                                </div>
                            </div >
                            {/* </Card> */}
                        </ScratchCard>
                    </div>
                </Modal>
            </div >
        )
    }
}

export default withRouter(
    withStyles(styles, { withTheme: true })(ScratchCardFun));