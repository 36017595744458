import { openAlertBox } from "./common/AlertMessage/AlertMessage";

export default {
  onUpdate: (registration) => {
    registration.unregister();
    window.location.reload(false);
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload(false);
      registration.skipWaiting(() => window.location.reload(false));
    }
    registration.skipWaiting();
  },
  onSuccess: (registration) => {
    console.info("service worker on success state");
  },
};
