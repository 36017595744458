import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import HeaderReferFriend from "../../../components/Layout/Header/HeaderReferFriend";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import Footer from "../../../components/Layout/Footer/Footer";
import ScratchCardFun from "./component/ScratchCard";
import { connect } from "react-redux";
import { merchantDetails, } from "../Dashboard/redux/action";
import { showLoader, } from "../../../../src/App/redux/action";
const mapDispatchToProps = {
    merchantDetails,
    showLoader,
};

const mapStateToProps = (state) => {
    return {
        merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    };
};
class ScratchCardConatiner extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Fragment>
                <HeaderReferFriend isScratchCard />
                <AlertMessage />
                <ScratchCardFun
                    merchantResponce={this.props.merchantDetailsResponse}
                    showLoader={this.props.showLoader}
                    getMerchantDetails={this.props.merchantDetails}
                />
                <Footer />
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ScratchCardConatiner));