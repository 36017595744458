
import React, { useEffect, useState } from 'react';
import { Button, Snackbar } from '@material-ui/core';

const HandleAppUpdate = ({ children }) => {
    const [state, setState] = useState({
        reg: null, open: false
    });

    const handleClose = () =>{
        if (state.reg.waiting) {
            // let waiting Service Worker know it should became active
            state.reg.waiting.postMessage('SKIP_WAITING');
            setState({
                reg: null, 
                open: false
            });
        }
    }

    const invokeServiceWorkerUpdateFlow = (registration) => {
        // TODO implement your own UI notification element
        setState({
            reg: registration, 
            open: true
        });
    }

    useEffect(() => {
        // check if the browser supports serviceWorker at all
        if ('serviceWorker' in navigator) {
            console.log('inside HOC if');
            // wait for the page to load
            window.addEventListener('load', async () => {
                // register the service worker from the file specified
                const registration = await navigator.serviceWorker.register('/service-worker.js')

                // ensure the case when the updatefound event was missed is also handled
                // by re-invoking the prompt when there's a waiting Service Worker
                if (registration.waiting) {
                    console.log('inside registration.waiting if');
                    invokeServiceWorkerUpdateFlow(registration);
                }

                // detect Service Worker update available and wait for it to become installed
                registration.addEventListener('updatefound', () => {
                    if (registration.installing) {
                        // wait until the new Service worker is actually installed (ready to take over)
                        registration.installing.addEventListener('statechange', () => {
                            if (registration.waiting) {
                                // if there's an existing controller (previous Service Worker), show the prompt
                                console.log('previouse SW registration.waiting if');
                                if (navigator.serviceWorker.controller) {
                                    console.log('inside navigator.serviceWorker.controller if');
                                    invokeServiceWorkerUpdateFlow(registration)
                                } else {
                                    // otherwise it's the first install, nothing to do
                                    console.log('Service Worker initialized for the first time')
                                }
                            }
                        })
                    }
                })

                // let refreshing = false;

                // // detect controller change and refresh the page
                // navigator.serviceWorker.addEventListener('controllerchange', () => {
                //     if (!refreshing) {
                //         window.location.reload();
                //         refreshing = true
                //     }
                // })
            })
        }

    }, []);

    const action = (
        <>
          <Button color="secondary" size="small" onClick={handleClose}>
            Update
          </Button>
        </>
      );

    return (
        <>
            {children}
            <Snackbar
                open={state.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="New update is available"
                action={action}
            /></>
    )
}

export default HandleAppUpdate;



