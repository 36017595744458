import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Number, bool } from "prop-types";
const mapStyles = {
  width: "100%",
  height: "50%",
};

const LoadingContainer = (props) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      Loading map please wait!...
    </div>
  );
};
export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
        center={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
        scrollwheel={this.props.scrollwheel}
        draggable={this.props.draggable}
        options={{ gestureHandling: "greedy" }}
        disableDefaultUI={true}
      >
        <Marker position={{ lat: this.props.lat, lng: this.props.lng }} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAgNI50Zk-ipGjXALAAA8gabw7TbZyw25c", //"AIzaSyDIe6CBuymf60DqeaqW54Ti4mNDPEiIq_U", // "AIzaSyDX2Sab6G7s52qQdtTJ6E0HmgMRxY-SZlY",
  LoadingContainer: LoadingContainer,
})(MapContainer);

MapContainer.propTypes = {
  lat: Number,
  lng: Number,
  scrollwheel: bool,
  draggable: bool,
};

MapContainer.defaultProps = {
  scrollwheel: true,
  draggable: true,
};
