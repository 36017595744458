import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Divider, ListItem, ListItemText, List } from "@material-ui/core";
import HOWITWORKS from "../../../../assets/icons/more/how_it_works.svg";
import FAQS from "../../../../assets/icons/more/faqs.svg";
import TERMS from "../../../../assets/icons/more/terms.svg";
import CONTACTUS from "../../../../assets/icons/more/contactus.svg";
import ABOUTUS from "../../../../assets/icons/more/aboutus.svg";
import FEEDBACK from "../../../../assets/icons/more/feedback.svg";
import LOCATOR from "../../../../assets/icons/more/locator.svg";
import LOGOUT from "../../../../assets/icons/more/logout.svg";
import BOOMER1 from "../../../../assets/icons/more/layer-3.png";
import BOOMER2 from "../../../../assets/icons/more/layer-3@2x.png";
import BOOMER3 from "../../../../assets/icons/more/layer-3@3x.png";
import { getStorageItem, setStorageItem } from "../../../../common/localStorage";

const styles = (theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%",
      position: "relative",
      overflowY: "auto",
      backgroundColor: theme.palette.common.globalBackground,
    },
    sortList: {
      "& .MuiTypography-body1": {
        fontSize: "20px",
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
        },
        [theme.breakpoints.down(481)]: { fontSize: "16px" },
        [theme.breakpoints.down(421)]: { fontSize: "15.5px" },
        [theme.breakpoints.down(381)]: { fontSize: "15px" },
        [theme.breakpoints.down(361)]: { fontSize: "14px" },
        [theme.breakpoints.down(321)]: { fontSize: "12px" },
      },
      "& .MuiList-padding": {
        padding: 0,
      },
      "& .MuiListItem-root": {
        padding: "16px",
        [theme.breakpoints.down(421)]: {
          padding: "12px 16px",
        },
        [theme.breakpoints.between(413, 415)]: {
          padding: "10px 16px",
        },
        [theme.breakpoints.down(401)]: {
          padding: "10px 16px",
        },
        [theme.breakpoints.down(321)]: {
          padding: "4px 16px",
        },
      },
    },
    img: {
      marginRight: "16px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectFit: "contain",
      objectPosition: "center",
      width: "38px",
      height: "24px",
      [theme.breakpoints.down(641)]: { width: "36px", height: "22px" },
      [theme.breakpoints.down(481)]: { width: "34px", height: "20px" },
      [theme.breakpoints.down(381)]: { width: "32px", height: "18px" },
      [theme.breakpoints.down(321)]: { width: "30px", height: "16px" },
    },
    dividerCss: {
      height: "0px",
    },
    howItWorksCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${HOWITWORKS})`,
      "mask-image": `url(.${HOWITWORKS})`,
      mask: `url(.${HOWITWORKS}) no-repeat center / contain`,
      "-webkit-mask": `url(.${HOWITWORKS}) no-repeat center / contain`,
    },
    faqsCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${FAQS})`,
      "mask-image": `url(.${FAQS})`,
      mask: `url(.${FAQS}) no-repeat center / contain`,
      "-webkit-mask": `url(.${FAQS}) no-repeat center / contain`,
    },

    termsCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${TERMS})`,
      "mask-image": `url(.${TERMS})`,
      mask: `url(.${TERMS}) no-repeat center / contain`,
      "-webkit-mask": `url(.${TERMS}) no-repeat center / contain`,
    },
    contactCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${CONTACTUS})`,
      "mask-image": `url(.${CONTACTUS})`,
      mask: `url(.${CONTACTUS}) no-repeat center / contain`,
      "-webkit-mask": `url(.${CONTACTUS}) no-repeat center / contain`,
    },
    aboutCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${ABOUTUS})`,
      "mask-image": `url(.${ABOUTUS})`,
      mask: `url(.${ABOUTUS}) no-repeat center / contain`,
      "-webkit-mask": `url(.${ABOUTUS}) no-repeat center / contain`,
    },
    feedbackCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${FEEDBACK})`,
      "mask-image": `url(.${FEEDBACK})`,
      mask: `url(.${FEEDBACK}) no-repeat center / contain`,
      "-webkit-mask": `url(.${FEEDBACK}) no-repeat center / contain`,
    },
    locatorCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${LOCATOR})`,
      "mask-image": `url(.${LOCATOR})`,
      mask: `url(.${LOCATOR}) no-repeat center / contain`,
      "-webkit-mask": `url(.${LOCATOR}) no-repeat center / contain`,
    },
    logoutCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${LOGOUT})`,
      "mask-image": `url(.${LOGOUT})`,
      mask: `url(.${LOGOUT}) no-repeat center / contain`,
      "-webkit-mask": `url(.${LOGOUT}) no-repeat center / contain`,
    },
    bottom_title: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      userSelect: "none",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.64,
      letterSpacing: "normal",
      textAlign: "right",
      color: "#7e7e7e",
      whiteSpace: "nowrap",
      [theme.breakpoints.down(641)]: { fontSize: "16px" },
      [theme.breakpoints.down(481)]: { fontSize: "12px" },
      [theme.breakpoints.down(381)]: { fontSize: "10px" },
      [theme.breakpoints.down(321)]: { fontSize: "8px" },
    },
    bottom_panel: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "Column",
      alignItems: "center",
      position: "relative",
      // bottom: "130px",
      width: "100%",
      margin: "1rem 0",
      // [theme.breakpoints.down(641)]: { bottom: "126px" },
      // [theme.breakpoints.down(481)]: { bottom: "80px" },
      // [theme.breakpoints.down(421)]: { bottom: "45px" },
      // [theme.breakpoints.down(413)]: { bottom: "50px" },
      // [theme.breakpoints.down(401)]: { bottom: "58px" },
      // [theme.breakpoints.down(381)]: { bottom: "48px" },
      // [theme.breakpoints.down(361)]: { bottom: "48px" },
      // [theme.breakpoints.down(321)]: { bottom: "36px" },
    },
    Layer_3: {
      objectFit: "contain",
      width: "250px",
      height: "100px",
      [theme.breakpoints.down(641)]: { width: "170px", height: "70px" },
      [theme.breakpoints.down(481)]: { width: "140px", height: "45px" },
      [theme.breakpoints.down(381)]: { width: "130px", height: "40px" },
      [theme.breakpoints.down(321)]: { width: "110px", height: "40px" },
    },
    btnContainer: {
      width: "100%",
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
    wrapper: {
      padding: 0,
      margin: "0 auto",
      display: "block",
      width: "100%",
      overflow: "hidden",
      maxHeight: "600px",
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "22px",
      height: "92px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    cancelButtons: {
      ...theme.palette.typography.cancelButtons,
      marginBottom: 0,
      fontSize: "22px",
      height: "92px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    app_version: {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      textAlign: "right",
      color: theme.palette.typography.texts.color,
      fontSize: "22px",
      [theme.breakpoints.down(641)]: { fontSize: "20px" },
      [theme.breakpoints.down(481)]: { fontSize: "16px" },
      [theme.breakpoints.down(421)]: { fontSize: "15.5px" },
      [theme.breakpoints.down(381)]: { fontSize: "15px" },
      [theme.breakpoints.down(361)]: { fontSize: "14px" },
      [theme.breakpoints.down(321)]: { fontSize: "12px" },
    },
    options: {
      marginBottom: "calc(100% - 700px)",
      [theme.breakpoints.down(641)]: { marginBottom: "calc(100% - 500px)" },
      [theme.breakpoints.down(481)]: { marginBottom: "calc(100% - 438px)" },
      [theme.breakpoints.down(421)]: { marginBottom: "calc(100% - 330px)" },
      [theme.breakpoints.between(413, 415)]: {
        marginBottom: "calc(100% - 370px)",
      },
      [theme.breakpoints.down(381)]: { marginBottom: "calc(100% - 346px)" },
      [theme.breakpoints.down(361)]: { marginBottom: "calc(100% - 350px)" },
      [theme.breakpoints.down(321)]: { marginBottom: "calc(100% - 248px)" },
    },
  };
};

const MoreComponent = (props) => {
  const [open, setOpen] = useState(false);
  const { classes } = props;
  const moreList = (
    <div className={classes.sortList}>
      <List>
        {[
          {
            clickEvent: () => props.history.push("/how-it-works"),
            text: "How The V Club works?",
            src: classes.howItWorksCss,
          },
          {
            clickEvent: () => props.history.push("/store-locator"),
            text: "Store Locator/Mall Directory",
            src: classes.locatorCss,
            // disabled: true,
          },
          {
            clickEvent: () => props.history.push("/feedback"),
            text: "Feedback",
            src: classes.feedbackCss,
          },
          {
            clickEvent: () => props.history.push("/faqs"),
            text: "FAQs",
            src: classes.faqsCss,
          },
          {
            clickEvent: () => props.history.push("/about-us"),
            text: "About Us",
            src: classes.aboutCss,
          },
          {
            clickEvent: () => props.history.push("/terms-of-use"),
            text: "Terms Of Use",
            src: classes.termsCss,
          },
          {
            clickEvent: () => props.history.push("/contact-us"),
            text: "Contact Us",
            src: classes.contactCss,
          },
          {
            clickEvent: () => setOpen(true),
            text: "Logout",
            src: classes.logoutCss,
          },
        ].map((item, index) => (
          <Fragment key={item.text}>
            <ListItem button onClick={item.clickEvent} disabled={item.disabled}>
              <span alt="" className={classes.img + " " + item.src} />
              <ListItemText primary={item.text} />
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
      <Divider className={classes.dividerCss} />
    </div>
  );
  return (
    <div className={classes.root}>
      <div className={classes.options}>{moreList}</div>
      <div className={classes.bottom_panel}>
        <div className={classes.bottom_title}>Powered By</div>
        <div className={classes.icons}>
          <img
            src={BOOMER1}
            srcSet={`${BOOMER2} 2x,${BOOMER3} 3x`}
            className={classes.Layer_3}
          />
        </div>
        <div className={classes.app_version}>
          {getStorageItem("currVersion")}
        </div>
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="md"
        className={classes.dialogModal}
      >
        <div className={classes.wrapper}>
          <div
            style={{
              fontWeight: 600,
              fontSize: "3.5vw",
              marginBottom: "1vh",
              marginTop: "3vh",
              textAlign: "center",
            }}
          >
            Do you want to logout?
          </div>
          <div className={classes.btnContainer}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <Button
                  disableRipple
                  color="primary"
                  variant="contained"
                  className={classes.doneBtn}
                  onClick={() => {
                    props.logoutUser();
                    setStorageItem("IsTabActive", "true");
                    console.log(getStorageItem("IsTabActive"));
                  }}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  disableRipple
                  color="primary"
                  variant="contained"
                  className={classes.cancelButtons}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default withRouter(
  withStyles(styles, { withTheme: true })(MoreComponent)
);
