import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { showLoader, changeActiveTab } from "../../../../../App/redux/action";
import { clearBrands, saveBrands } from "./redux/actions";
import defaulticon from "../../../../../assets/icons/more/Default_Image.png";
import down_arrow from "../../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import AlertMessage, {
  openAlertBox,
} from "../../../../../common/AlertMessage/AlertMessage";
import { stockAPI } from "../../../../../common/axiosInstance";
import {
  apiData,
  headerScreenLabels,
} from "../../../../../common/common-types";
import Header from "../../../../../components/Layout/Header/HeaderSubmitBill";
import Search from "../../../../../components/Search/StoreSearch";
import MyRoute from "../../../../../hoc/MyRoute";
import BrandDetails from "./BrandDetails";
import Categories from "./Categories";
import Floors from "./Floors";
import MallMap from "./MallMap";
import { styles } from "./StoreStyles";
import {
  removeStorageItem,
  setStorageItem,
  getStorageItem,
} from "../../../../../common/localStorage";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    fontSize: 20,
    padding: "25px 24px 0px 13px",
    textAlign: "left",
    [theme.breakpoints.down(641)]: {
      fontSize: 21,
      padding: "20px 24px 0px 13px",
    },
    [theme.breakpoints.down(481)]: {
      fontSize: 14,
      padding: "11px 24px 0px 13px",
    },
    [theme.breakpoints.down(421)]: {
      fontSize: 14,
      padding: "11px 24px 0px 13px",
    },
    [theme.breakpoints.down(401)]: {
      fontSize: 13,
      padding: "12px 24px 0px 13px",
    },
    [theme.breakpoints.down(381)]: {
      fontSize: 12,
      padding: "9px 24px 0px 13px",
    },
    [theme.breakpoints.down(361)]: {
      fontSize: 12,
      padding: "8px 24px 0px 13px",
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 10,
      padding: "6px 24px 0px 13px",
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

const mapStateToProps = (state) => {
  return {
    merchantDetails: state.reducerDashboard.merchantDetailsResponse,
    brandList: state.reducerBrand.brandList,
    nextpage: state.reducerBrand.nextpage,
    curpage: state.reducerBrand.curpage,
    hasMore: state.reducerBrand.hasMore,
    searchtext: state.reducerBrand.searchtext,
  };
};

const mapDispatchToProps = {
  showLoader,
  changeActiveTab,
  clearBrands,
  saveBrands,
};

class StoreLocator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showMap: false,
      isBrand: false,
      locations: [],
      selectedlocation: 0,
      brands: [],
      nextpage: 1,
      curpage: "",
      searchtext: "",
      hasMore: false,
      floorsaAndCategories: null,
      brandid: null,
      brand: null,
      selectedFloor: null,
    };
  }

  componentDidMount() {
    this.props.changeActiveTab(4);
    this.getLocations();
  }

  componentDidUpdate(preProps, preState) {
    if (
      this.props.location.pathname.split("/")[2] &&
      this.props.location.pathname.split("/")[2] !== "floor-plan" &&
      this.props.location.pathname !== preProps.location.pathname
    ) {
      this.setState(
        {
          isBrand: true,
          brandid: this.props.location.pathname.split("/")[2],
        },
        () => {
          this.getBrandDetails();
        }
      );
    } else if (
      preProps.location.pathname.split("/")[2] &&
      preProps.location.pathname.split("/")[2] === "floor-plan" &&
      this.props.location.pathname !== preProps.location.pathname
    ) {
      this.setState({
        showMap: false,
        isBrand: false,
        brandid: null,
        brand: null,
        selectedFloor: null,
      });
    } else if (this.props.location.pathname !== preProps.location.pathname) {
      if (this.state.activeTab === 0) {
        this.setState(
          {
            brands: this.props.brandList,
            nextpage: this.props.nextpage,
            curpage: this.props.curpage,
            hasMore: this.props.hasMore,
            searchtext: this.props.searchtext,
            isBrand: false,
            brandid: null,
            brand: null,
          },
          () => {
            if (
              getStorageItem("brandid") &&
              document.getElementById(getStorageItem("brandid"))
            ) {
              document
                .getElementById(getStorageItem("brandid"))
                .scrollIntoView({
                  behavior: "auto",
                  block: "center",
                  inline: "center",
                });
              removeStorageItem("brandid");
              //commented this code to resolve the issue of on back from back details screen bracnlidt was getting clear
              // this.props.clearBrands();
            }
          }
        );
      } else {
        this.setState({
          isBrand: false,
          brandid: null,
          brand: null,
        });
      }
    }
  }

  componentWillUnmount() {
    // this.props.clearBrands();
    // removeStorageItem("brandid");
  }

  getLocations() {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    stockAPI(data, "POST", "/getStoreLocations")
      .then((response) => {
        if (response && response.locations && response.locations[0]) {
          const rec = response.locations.map((x) => ({
            ...x,
            location_name: `${
              this.props.merchantDetails
                ? `${this.props.merchantDetails.merchant_name}, `
                : ""
            }${x.location_name}`,
          }));
          this.setState(
            {
              locations: rec,
              selectedlocation: response.locations[0].id,
              brands: [],
              nextpage: 1,
              curpage: "",
              searchtext: "",
            },
            () => {
              if (
                this.props.location.pathname.split("/")[2] &&
                this.props.location.pathname.split("/")[2] !== "floor-plan"
              ) {
                this.setState(
                  {
                    isBrand: true,
                    brandid: this.props.location.pathname.split("/")[2],
                    brand: null,
                  },
                  () => {
                    this.getBrandDetails();
                  }
                );
              } else {
                this.getBrand();
                this.getFloorsAndCategories();
              }
            }
          );
        }
      })
      .catch((err) => {
        openAlertBox(err && err.message, "error");
      });
  }

  getFloorsAndCategories() {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    data.append("location_id", this.state.selectedlocation);
    data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    if (this.state.selectedlocation) {
      this.props.showLoader(true);
      stockAPI(data, "POST", "/getStoreLocatorFloorCatDetails")
        .then((response) => {
          this.props.showLoader(false);
          if (response) {
            this.setState({
              floorsaAndCategories: response,
            });
          }
        })
        .catch((err) => {
          openAlertBox(err && err.message, "error");
        });
    }
  }

  getBrand = (isscroll) => {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    data.append("platform", apiData.platform);
    data.append("location_id", this.state.selectedlocation);
    data.append("page_num", this.state.nextpage);
    data.append("search_text", this.state.searchtext);
    if (this.state.nextpage && this.state.selectedlocation) {
      !isscroll && this.props.showLoader(true);
      stockAPI(data, "POST", "/getStoreLocatorBrandDetails")
        .then((response) => {
          this.props.showLoader(false);
          if (response && response.brands) {
            this.setState(
              {
                brands: [...this.state.brands, ...response.brands],
                nextpage: response.page_num,
                curpage: this.state.nextpage,
                hasMore: response.page_num ? true : false,
              },
              () => {
                this.props.saveBrands({
                  brandList: [...this.state.brands],
                  nextpage: this.state.nextpage,
                  curpage: this.state.curpage,
                  hasMore: this.state.hasMore,
                  searchtext: this.state.searchtext,
                });
              }
            );
          }
        })
        .catch((err) => {
          openAlertBox(err && err.message, "error");
        });
    }
  };

  getBrandDetails = () => {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    data.append("platform", apiData.platform);
    data.append("location_id", this.state.selectedlocation);
    data.append("brand_id", this.state.brandid);
    this.props.showLoader(true);
    if (this.state.brandid) {
      stockAPI(data, "POST", "/getStoreProfileDetails")
        .then((response) => {
          this.props.showLoader(false);
          if (response) {
            this.setState({
              brand: response,
            });
          }
        })
        .catch((err) => {
          this.props.showLoader(false);
          openAlertBox(err && err.message, "error");
        });
    }
  };

  handleTabChange = (e, tabNumber) => {
    this.setState(
      {
        activeTab: tabNumber,
      },
      () => {
        if (tabNumber === 0) {
          this.setState(
            {
              brands: [],
              searchtext: "",
              nextpage: 1,
              curpage: "",
              hasMore: false,
            },
            () => {
              this.getBrand();
            }
          );
        } else if (
          !this.state.floorsaAndCategories &&
          (tabNumber === 1 || tabNumber === 2)
        ) {
          this.getFloorsAndCategories();
        }
      }
    );
  };

  showMap = (flag) => {
    this.setState(
      {
        showMap: flag,
        isBrand: false,
        brandid: null,
        brand: null,
        selectedFloor: null,
      },
      () => {
        if (flag) {
          this.props.history.push(`/store-locator/floor-plan`);
        } else {
          this.props.history.push(`/store-locator`);
        }
      }
    );
  };

  getFloorPlan = (id) => {
    this.setState(
      {
        showMap: true,
        isBrand: false,
        brandid: null,
        brand: null,
        selectedFloor: id,
      },
      () => {
        this.props.history.push(`/store-locator/floor-plan`);
      }
    );
  };

  showBrand = (id) => {
    this.setState(
      {
        isBrand: true,
        brandid: id,
      },
      () => {
        setStorageItem("brandid", id);
        this.props.history.push(`/store-locator/${id}`);
      }
    );
  };

  back = () => {
    if (this.state.isBrand) {
      this.setState(
        {
          isBrand: false,
          brandid: null,
          brand: null,
          selectedFloor: null,
        },
        () => {
          this.props.history.goBack();
          // this.getBrand();
        }
      );
    } else if (this.state.showMap) {
      this.setState(
        {
          showMap: false,
          isBrand: false,
          brandid: null,
          brand: null,
          selectedFloor: null,
        },
        () => {
          this.props.history.goBack();
        }
      );
    } else {
      this.props.history.goBack();
    }
  };

  searchBrand = (value) => {
    this.setState({
      searchtext: value,
    });
  };

  resetSearchText = () => {
    this.setState(
      {
        searchtext: "",
        brands: [],
        nextpage: 1,
        curpage: "",
        hasMore: false,
      },
      () => {
        this.getBrand();
      }
    );
  };

  searchCall = (e) => {
    e.preventDefault();
    this.setState(
      {
        brands: [],
        nextpage: 1,
        curpage: "",
        hasMore: false,
      },
      () => {
        this.getBrand();
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      activeTab,
      showMap,
      isBrand,
      locations = [],
      selectedlocation,
    } = this.state;
    const {
      brands,
      hasMore,
      searchtext,
      floorsaAndCategories,
      brand,
      selectedFloor,
    } = this.state;
    const tabContainer = (
      <Paper
        className={classnames(classes.tabContainer, {
          [classes.brLTB]: activeTab === 0,
          [classes.brRTB]: activeTab === 2,
        })}
        style={{ boxShadow: "0 0 3px rgba(0,0,0,0.3)" }}
      >
        <Tabs
          classes={{
            indicator: classnames(classes.indicator, {
              [classes.firstIndicator]: activeTab === 0,
              [classes.lastIndicator]: activeTab === 2,
            }),
          }}
          value={activeTab}
          onChange={this.handleTabChange}
          centered
        >
          <Tab
            label="Brands"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={false}
          />
          <Tab
            label="Floors"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={false}
          />
          <Tab
            label="Categories"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={false}
          />
        </Tabs>
      </Paper>
    );

    return (
      <Fragment>
        <Header
          screenLabel={
            !showMap
              ? headerScreenLabels.storeLocator
              : headerScreenLabels.showmall
          }
          isBrand={isBrand}
          back={this.back}
          showMap={this.showMap}
        />
        <AlertMessage />
        <MyRoute
          fullHeight={true}
          billUploadStatus={this.props.uploadingStatus}
        >
          {!showMap ? (
            <div style={{ height: "100%" }}>
              {isBrand ? (
                <BrandDetails {...this.props} brand={brand || {}} />
              ) : (
                <div style={{ height: "100%" }}>
                  <div>
                    <Card className={classes.subHeader}>
                      <CardContent className={classes.cardpadding}>
                        <div className={classes.select_box}>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <Select
                              value={selectedlocation}
                              onChange={(e) =>
                                this.setState(
                                  {
                                    selectedlocation: e.target.value,
                                    brands: [],
                                    nextpage: 1,
                                    searchtext: "",
                                    hasMore: false,
                                    floorsaAndCategories,
                                  },
                                  () => {
                                    if (activeTab === 0) {
                                      this.getBrand();
                                    } else {
                                      this.getFloorsAndCategories();
                                    }
                                  }
                                )
                              }
                              input={<CustomInput />}
                              IconComponent={() => (
                                <img
                                  className={classes.down_icon}
                                  src={down_arrow}
                                  alt=""
                                />
                              )}
                              MenuProps={MenuProps}
                            >
                              <MenuItem disabled value={0}>
                                Select location
                              </MenuItem>
                              {locations.map((loc) => (
                                <MenuItem key={loc.id} value={loc.id}>
                                  {loc.location_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </CardContent>
                    </Card>

                    <Grid
                      className={classes.root}
                      style={{ paddingBottom: activeTab == 0 && 0 }}
                    >
                      <Grid>{tabContainer}</Grid>
                    </Grid>
                    {activeTab === 0 && (
                      <Grid
                        className={classes.rootSearch}
                        style={{ paddingBottom: 0 }}
                      >
                        <Search
                          onSearchTextChange={(e) =>
                            this.searchBrand(e.target.value)
                          }
                          searchText={searchtext}
                          className="p15"
                          paddingBottom="pB10"
                          resetSearchText={this.resetSearchText}
                          searchCall={this.searchCall}
                        />
                      </Grid>
                    )}
                  </div>
                  <div
                    className={
                      activeTab === 0
                        ? classes.brandwrapper
                        : classes.allwrapper
                    }
                  >
                    {activeTab === 0 ? (
                      <InfiniteScroll
                        loadMore={() => {
                          if (
                            this.state.nextpage &&
                            this.state.curpage &&
                            this.state.nextpage !== this.state.curpage
                          )
                            this.getBrand(true);
                        }}
                        hasMore={hasMore}
                        useWindow={true}
                      >
                        {brands.map((brand) => (
                          <Card
                            key={brand.brand_name}
                            className={classes.brandcontainer}
                            id={brand.brand_id}
                            onClick={() => this.showBrand(brand.brand_id)}
                            style={{ boxShadow: "0 0 3px rgba(0,0,0,0.3)" }}
                          >
                            <Grid
                              container
                              style={{ height: "100%", margin: 0 }}
                            >
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                style={{ height: "100%" }}
                                className={classes.imgwrapper}
                              >
                                <img
                                  src={brand.brand_logo || defaulticon}
                                  alt={"brand-img"}
                                  className={classes.brandimg}
                                  style={{ objectFit: "contain" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                sm={9}
                                style={{
                                  display: "table",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                  }}
                                  className={classes.branddetails}
                                >
                                  <div style={{ float: "left" }}>
                                    <div>
                                      <strong>{brand.brand_name}</strong>
                                    </div>
                                    <div>{brand.category_name}</div>
                                    <div>{brand.shop_numbers}</div>
                                  </div>
                                  <div style={{ float: "right" }}>
                                    <ChevronRightIcon
                                      className={classes.rightarrowicon}
                                    />
                                  </div>
                                  <div style={{ clear: "both" }}></div>
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                      </InfiniteScroll>
                    ) : activeTab === 1 ? (
                      <Floors
                        showBrand={this.showBrand}
                        {...this.props}
                        {...this.state}
                        floors={
                          floorsaAndCategories
                            ? floorsaAndCategories.floors
                            : []
                        }
                        getFloorPlan={this.getFloorPlan}
                      />
                    ) : (
                      <Categories
                        showBrand={this.showBrand}
                        {...this.props}
                        {...this.state}
                        categories={
                          floorsaAndCategories
                            ? floorsaAndCategories.categories
                            : []
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <MallMap
              {...this.props}
              locations={locations}
              floors={floorsaAndCategories ? floorsaAndCategories.floors : []}
              selectedFloor={selectedFloor}
            />
          )}
        </MyRoute>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(StoreLocator)));
